import React, { useEffect, useState } from "react";
import { sendToken } from "../../utils/TransactionUtils";
import { sepolia } from "../../models/Chain";
import { Account } from "../../models/Account";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AccountTransactions from "./AccountTransactions";
import { ethers } from "ethers";
import { toFixedIfNecessary } from "../../utils/AccountUtils";
// import { QrReader } from "react-qr-reader";
// import QRCodeGenerator from "./QRCodeGenerator";
import "./Account.css";

interface AccountDetailProps {
  account: Account;
}

const AccountDetail: React.FC<AccountDetailProps> = ({ account }) => {
  const [destinationAddress, setDestinationAddress] = useState<string>("");
  const [userInput, setUserInput] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [balance, setBalance] = useState<string>(account.balance);
  // const [showQrGenerator, setShowQrGenerator] = useState<boolean>(false);
  // const [showQrReader, setShowQrReader] = useState<boolean>(false);

  const [networkResponse, setNetworkResponse] = useState<{
    status: null | "pending" | "complete" | "error";
    message: string | React.ReactElement;
  }>({
    status: null,
    message: "",
  });
  const handleCopy = () => {
    navigator.clipboard.writeText(account.address);
    toast.success('Address copied to clipboard!');
  };

  

  useEffect(() => {
    const fetchData = async () => {
      const provider = new ethers.providers.JsonRpcProvider(sepolia.rpcUrl);
      try {
        const accountBalance = await provider.getBalance(account.address);
        setBalance(
          String(toFixedIfNecessary(ethers.utils.formatEther(accountBalance)))
        );
      } catch (error) {
        console.error("Error fetching balance:", error);
      }
    };
    fetchData();
  }, [account.address]);

  const handleDestinationAddressChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUserInput(event.target.value);
    setDestinationAddress("");
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(event.target.value);
  };

  const transfer = async () => {
    setNetworkResponse({
      status: "pending",
      message: "",
    });

    try {
      const { receipt } = await sendToken(
        Number(amount),
        account.address,
        destinationAddress !== "" ? destinationAddress : userInput,
        account.privateKey
      );

      if (receipt.status === 1) {
        setNetworkResponse({
          status: "complete",
          message: (
            <p>
              Transfer complete!{" "}
              <a
                href={`${sepolia.blockExplorerUrl}/tx/${receipt.transactionHash}`}
                target="_blank"
                rel="noreferrer"
              >
                View transaction
              </a>
            </p>
          ),
        });
      } else {
        console.log(`Failed to send ${receipt}`);
        setNetworkResponse({
          status: "error",
          message: JSON.stringify(receipt),
        });
      }
    } catch (error: any) {
      console.error("Error sending token:", error);

      if (error.code === ethers.errors.INSUFFICIENT_FUNDS) {
        setNetworkResponse({
          status: "error",
          message:
            "Insufficient funds for the transaction cost. Please ensure you have enough ETH to cover the gas fees.",
        });
      } else {
        setNetworkResponse({
          status: "error",
          message: error.reason || JSON.stringify(error),
        });
      }
    }
    setAmount("");
  };

  // const handleScan = useCallback((result: { getText: () => string } | null) => {
  //   if (result) {
  //     let fulladdress = result.getText();
  //     let address = fulladdress.split(':')[1];
  //     console.log("Scanned address:", address);
  //     setDestinationAddress(address);
  //     setUserInput("");
  //     setShowQrReader(false);
  //   }
  // }, []);

  // const handleError = (err: any) => {
  //   console.error("QR Reader error:", err);
  // };

  // const videoConstraints = {
  //   facingMode: "environment",
  // };

  return (
    <div className="AccountDetail">
      <div className="d-flex">
      <h6>
        Address:
      </h6>
      <h6>
      <a
          href={`https://sepolia.etherscan.io/address/${account.address}`}
          target="_blank"
          rel="noreferrer"
          className="ms-2"
        >
          {account.address}
        </a>
      </h6>
      <button className="icon-button ms-2" onClick={handleCopy}>
          <FontAwesomeIcon icon={faCopy} />
        </button>
      </div>
      <ToastContainer />
      <h6>
      Balance: <span className="ms-2">{balance} ETH</span>
      </h6>

      <div className="form-group">
        <label>Destination Address:</label>
        <input
          className="form-control"
          type="text"
          value={destinationAddress !== "" ? destinationAddress : userInput}
          disabled={destinationAddress !== ""}
          onChange={handleDestinationAddressChange}
        />
      </div>

      <div className="form-group">
        <label>Amount:</label>
        <input
          className="form-control"
          type="text"
          value={amount}
          // disabled={destinationAddress !== ""}
          onChange={handleAmountChange}
        />
      </div>

      <button
        className="btn btn-primary ms-0 mt-2"
        type="button"
        onClick={transfer}
        disabled={!amount || networkResponse.status === "pending"}
      >
        Send {amount} ETH
      </button>

      {/* <button
        className="btn btn-outline-success mt-3"
        onClick={() => setShowQrReader(!showQrReader)}
      >
        {showQrReader ? "Hide QR Scanner" : "Scan QR to Transfer Money"}
      </button> */}

      {/* {showQrReader && (
        <div className="mt-3" style={{ width: "100%" }}>
          <QrReader
            constraints={videoConstraints}
            onResult={(result, error) => {
              if (result) {
                handleScan(result);
              }
              if (error) {
                handleError(error);
              }
            }}
          />
        </div>
      )} */}

      {/* <button
        className="btn btn-outline-info mt-3"
        onClick={() => setShowQrGenerator(!showQrGenerator)}
      >
        {showQrGenerator ? "Hide QR Code" : "Generate QR Code"}
      </button>

      {showQrGenerator && (
        <div className="mt-3">
          <QRCodeGenerator address={account.address} />
        </div>
      )} */}

      {networkResponse.status && (
        <>
          {networkResponse.status === "pending" && (
            <p>Transfer is pending...</p>
          )}
          {networkResponse.status === "complete" && (
            <p>{networkResponse.message}</p>
          )}
          {networkResponse.status === "error" && (
            <p>
              Error occurred while transferring tokens:{" "}
              {networkResponse.message}
            </p>
          )}
        </>
      )}

      <AccountTransactions account={account} />
    </div>
  );
};

export default AccountDetail;
