import React, { useCallback, useEffect, useState } from 'react';
import { generateAccount } from '../../utils/AccountUtils';
import { importToken } from '../../utils/ImportToken';
import { faUser, faUpload, faFileImport, faExchangeAlt, faPlus, faCopy } from '@fortawesome/free-solid-svg-icons';
import { transferToken } from '../../utils/TransferToken';
import { Account } from '../../models/Account';
import AccountDetail from './AccountDetail';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isDisabled } from '@testing-library/user-event/dist/utils';

Modal.setAppElement('#root');

const recoveryPhraseKeyName = 'recoveryPhrase';

function AccountCreate() {
  const [seedphrase, setSeedphrase] = useState<string>('');
  const [account, setAccount] = useState<Account | null>(null);
  const [tokenAddress, setTokenAddress] = useState<string>('');
  const [recipientAddress, setRecipientAddress] = useState<string>('');
  const [transferAmount, setTransferAmount] = useState<string>('');
  const [activeTab, setActiveTab] = useState<string>('create');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [accountKey, setAccountKey] = useState('');
  const [TokenName, setTokenName ] = useState('');
  const [TokenDecimals, setTokenDecimals ] = useState('');
  const [TokenBalance, setTokenBalance ] = useState('');
  const [TokenSymbol, setTokenSymbol ] = useState('');

  const handleCopy = () => {
    navigator.clipboard.writeText(accountKey);
    toast.success('Seed Phrase copied to clipboard!');
  };

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSeedphrase(event.target.value);
  }
  function handleTokenName(event: React.ChangeEvent<HTMLInputElement>) {
    setTokenName(event.target.value);
  }
  function handleTokenDecimals(event: React.ChangeEvent<HTMLInputElement>) {
    setTokenDecimals(event.target.value);
  }
  function handleTokenBalance(event: React.ChangeEvent<HTMLInputElement>) {
    setTokenBalance(event.target.value);
  }
  function handleTokenSymbol(event: React.ChangeEvent<HTMLInputElement>) {
    setTokenSymbol(event.target.value);
  }

  const handleImportAccountKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      importAccount(seedphrase);
    }
  };
  const recoverAccount = useCallback(async (recoveryPhrase: string) => {
    try {
      const result = await generateAccount(recoveryPhrase);
      setAccount(result.account);
      if (localStorage.getItem(recoveryPhraseKeyName) !== recoveryPhrase) {
        localStorage.setItem(recoveryPhraseKeyName, recoveryPhrase);
      }
    } catch (err) {
      handleError(err);
    }
  }, []);

  const importAccount = useCallback(async (seedPhrase: string) => {
    try {
      const result = await generateAccount(seedPhrase);
      setAccount(result.account);
      setSeedphrase('')
      setActiveTab('create'); // Hide input after import
    } catch (err) {
      handleError(err);
    }
  }, []);

  const handleImportToken = async () => {
    try {
      await importToken(tokenAddress);
      setTokenAddress(''); // Clear input field
      const name : any = localStorage.getItem('TokenName')
      const symbol : any = localStorage.getItem('TokenSymbol')
      const decimals : any = localStorage.getItem('TokenDescimals')
      const balance : any = localStorage.getItem('Tokenbalance')
      setTokenName(name);
      setTokenDecimals(decimals); 
      setTokenSymbol(symbol);
      setTokenBalance(balance); 
      setActiveTab('transferToken'); // Hide input after import
    } catch (err) {
      handleError(err);
    }
  };

  const handleTransferToken = async () => {
    if (!account) {
      console.error('Account not found');
      return;
    }
    try {
      await transferToken(tokenAddress, recipientAddress, transferAmount, account.privateKey);
      setRecipientAddress(''); // Clear input field
      setTransferAmount(''); // Clear input field
      setActiveTab(''); // Hide input after transfer
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    const localStorageRecoveryPhrase = localStorage.getItem(recoveryPhraseKeyName);
    if (localStorageRecoveryPhrase) {
      setSeedphrase(localStorageRecoveryPhrase);
      recoverAccount(localStorageRecoveryPhrase);
    }
  }, [recoverAccount]);

  async function createAccount() {
    try {
      const result = await generateAccount();
      setAccount(result.account);
      setAccountKey(result.seedPhrase); // Assuming the result contains a key
      setModalIsOpen(true);
    } catch (err) {
      handleError(err);
    }
  }

  const handleError = (err: any) => {
    console.error(err);
  };

  return (
    <div className='AccountCreate p-5 pt-4 m-3 mt-0 card shadow'>
      <h1 className='text-center'>ARK Wallet</h1>
      <div className='tabs mt-2'>
        <button
          type="button"
          className={`btn ${activeTab === 'create' ? 'btn-primary' : 'btn-outline-primary'} ms-0`}
          onClick={() => setActiveTab('create')}
        >
          <FontAwesomeIcon icon={faUser} className="me-2" />
          Account Details
        </button>
        <button
          type="button"
          className={`btn ml-3 ${activeTab === 'importAccount' ? 'btn-primary' : 'btn-outline-primary'}`}
          onClick={() => setActiveTab('importAccount')}
        >
          <FontAwesomeIcon icon={faFileImport} className="me-2" />
          Import Account
        </button>
        <button
          type="button"
          className={`btn ml-3 ${activeTab === 'importToken' ? 'btn-primary' : 'btn-outline-primary'}`}
          onClick={() => setActiveTab('importToken')}
        >
          <FontAwesomeIcon icon={faUpload} className="me-2" />
          Import Token
        </button>
        <button
          type="button"
          className={`btn ml-3 ${activeTab === 'transferToken' ? 'btn-primary' : 'btn-outline-primary'} ms-0`}
          onClick={() => setActiveTab('transferToken')}
        >
          <FontAwesomeIcon icon={faExchangeAlt} className="me-2" />
          Transfer Token
        </button>
      </div>
      <form className='pt-3' onSubmit={event => event.preventDefault()}>
        {activeTab === 'create' && account == null && (
          <button type="button" className="btn btn-outline-primary" onClick={createAccount}>
            Create Account
             <FontAwesomeIcon icon={faPlus} className="ms-2" />
          </button>
        )}
         {activeTab === 'create' && account && (
        <>
          <hr className='mt-0' />
          <button type="button" className="btn btn-primary ms-0 mb-3 btn-create" onClick={createAccount}>
             <FontAwesomeIcon icon={faPlus} className="me-2" />
            Create New
          </button>
          <AccountDetail account={account} />
        </>
      )}
        {activeTab === 'importAccount' && (
          <div className="form-group mt-3">
            <label><strong>Seedphrase / private key :</strong></label>
            <input
              type="text"
              placeholder='Seedphrase or private key for import account'
              className="form-control mt-2"
              value={seedphrase}
              onChange={handleChange}
              onKeyDown={handleImportAccountKeyDown}
            />
            <button
              type="button"
              className="btn btn-primary mt-2"
              onClick={() => importAccount(seedphrase)}
              disabled={!seedphrase}
            >
              Import
            </button>
          </div>
        )}
        {activeTab === 'importToken' && (
          <div className="form-group mt-3">
            <input
              type="text"
              placeholder='Token Contract Address'
              className="form-control"
              value={tokenAddress}
              onChange={e => setTokenAddress(e.target.value)}
            />
            <button
              type="button"
              className="btn btn-primary mt-2"
              onClick={handleImportToken}
              disabled={!tokenAddress}
            >
              Import Token
            </button>
          </div>
        )}
        {activeTab === 'transferToken' && (
          <>
          <div>
            <h5 className='text-center'>Token Details</h5>
           <div className='row mt-3 token-details'>
            <div className="col-12 col-md-6">
            <p className='mb-1'><strong>Token Name :</strong> </p>
            <input
              type="text"
              value={TokenName}
              onChange={handleTokenName}
              className="form-control"
              disabled
            />
            <p className='mb-0'><strong>Token Symbol :</strong> </p>
            <input
              type="text"
              value={TokenSymbol}
              onChange={handleTokenSymbol}
              className="form-control"
              disabled
            />
            </div>
            <div className="col-12 col-md-6">
            <p className='mb-1'><strong>Token Decimals :</strong> </p>
            <input
              type="text"
              className="form-control"
              value={TokenDecimals}
              onChange={handleTokenDecimals}
              disabled
            />
            <p className='mb-0'><strong>Token Balance :</strong> </p>
            <input
              type="text"
              className="form-control"
              disabled
              value={TokenBalance}
              onChange={handleTokenBalance}
            />
            </div>
           </div>
          </div>
            <div className="form-group mt-3">
              <label><strong>Recipient Address : </strong></label>
              <input
                type="text"
                placeholder='Recipient Address'
                className="form-control mt-2"
                value={recipientAddress}
                onChange={e => setRecipientAddress(e.target.value)}
              />
            </div>
            <div className="form-group mt-2">
              <label><strong>Amount to Transfer</strong></label>
              <input
                type="text"
                placeholder='Amount to Transfer'
                className="form-control mt-2"
                value={transferAmount}
                onChange={e => setTransferAmount(e.target.value)}
              />
            </div>
            <button
              type="button"
              className="btn btn-primary ms-0 mt-2"
              onClick={handleTransferToken}
              disabled={!recipientAddress || !transferAmount}
            >
              Transfer Token
            </button>
          </>
        )}
      </form>
      
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Account Created successfully"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <div className='modal-header'>
        <h5 className='mb-0'>Account Created successfully</h5>
        </div>
       <div className='modal-body'>
        <label><strong>Seed Phrase :</strong></label>
       <div className='d-flex align-items-center'>
       <input
          className="form-control"
          type="text"
          value={accountKey}
          disabled
        />
        <button className="icon-button ms-2 mt-1" onClick={handleCopy}>
          <FontAwesomeIcon icon={faCopy} />
        </button>
       </div>
       <ToastContainer />
       <div className='text-center'>
        <button className='btn btn-outline-primary' onClick={() => setModalIsOpen(false)}>Close</button>
       </div>
       </div>
      </Modal>
    </div>
  );
}

export default AccountCreate;
